<template>
  <div class="news-template">
    <div class="header"></div>
    <div class="title">
      <h3>公司简介</h3>
    </div>
    <el-divider></el-divider>
    <div class="content">
      <section class="met-editor clearfix">
        <table
          width="1161"
          class="table table-striped table-bordered table-hover"
        >
          <tbody style="padding: 0px; margin: 0px">
            <tr class="firstRow" style="padding: 0px; margin: 0px"></tr>
          </tbody>
        </table>
        <p
          class="ordinary-output target-output"
          style="
            padding: 0px;
            margin-bottom: 0px;
            word-wrap: break-word;
            word-break: normal;
            color: rgb(106, 106, 106);
            font-family: Arial, 微软雅黑;
            font-size: 13px;
            white-space: normal;
            background-color: rgb(248, 248, 248);
          "
        >
          <span
            style="
              padding: 0px;
              margin: 0px;
              font-family: 微软雅黑, MicrosoftYaHei;
              font-size: 14px;
              color: rgb(89, 89, 89);
            "
            ><strong style="padding: 0px; margin: 0px">我们是谁？</strong></span
          >
        </p>
        <p
          class="ordinary-output target-output"
          style="
            padding: 0px;
            margin-bottom: 0px;
            word-wrap: break-word;
            word-break: normal;
            color: rgb(106, 106, 106);
            font-family: Arial, 微软雅黑;
            font-size: 13px;
            white-space: normal;
            background-color: rgb(248, 248, 248);
          "
        >
          <span style="font-family: 微软雅黑, MicrosoftYaHei; font-size: 14px"
            ><strong style="padding: 0px; margin: 0px"
              >只为做更好的软件平台！</strong
            ><span
              style="font-family: 微软雅黑, MicrosoftYaHei; font-size: 16px"
              ><span
                style="font-family: 微软雅黑, MicrosoftYaHei; font-size: 16px"
                microsoft=""
                ><strong style="padding: 0px; margin: 0px"></strong></span
              ><strong style="padding: 0px; margin: 0px"></strong></span
            ><strong style="padding: 0px; margin: 0px"></strong
            ><br data-filtered="filtered" style="padding: 0px; margin: 0px"
          /></span>
        </p>
        <p
          class="ordinary-output target-output"
          style="
            padding: 0px;
            margin-bottom: 0px;
            word-wrap: break-word;
            word-break: normal;
            color: rgb(106, 106, 106);
            font-family: Arial, 微软雅黑;
            font-size: 13px;
            white-space: normal;
            background-color: rgb(248, 248, 248);
          "
        >
          <span style="font-family: 微软雅黑, MicrosoftYaHei; font-size: 14px"
            ><strong style="padding: 0px; margin: 0px"><br /></strong
          ></span>
        </p>
        <p
          class="ordinary-output target-output"
          style="
            padding: 0px;
            margin-bottom: 0px;
            word-wrap: break-word;
            word-break: normal;
            color: rgb(106, 106, 106);
            font-family: Arial, 微软雅黑;
            font-size: 13px;
            white-space: normal;
            background-color: rgb(248, 248, 248);
          "
        >
          <span style="font-family: 微软雅黑, MicrosoftYaHei; font-size: 14px"
            ><strong>中软汇金——中国软件汇集金品</strong
            ><strong>。</strong></span
          >
        </p>
        <p
          class="ordinary-output target-output"
          style="
            padding: 0px;
            margin-bottom: 0px;
            word-wrap: break-word;
            word-break: normal;
            color: rgb(106, 106, 106);
            font-family: Arial, 微软雅黑;
            font-size: 13px;
            white-space: normal;
            background-color: rgb(248, 248, 248);
          "
        >
          <span style="font-family: 微软雅黑, MicrosoftYaHei; font-size: 14px"
            ><strong><br /></strong
          ></span>
        </p>
        <p
          class="ordinary-output target-output"
          style="
            padding: 0px;
            margin-bottom: 0px;
            word-wrap: break-word;
            word-break: normal;
            color: rgb(106, 106, 106);
            font-family: Arial, 微软雅黑;
            font-size: 13px;
            white-space: normal;
            background-color: rgb(248, 248, 248);
          "
        >
          <span style="font-family: 微软雅黑, MicrosoftYaHei; font-size: 14px"
            ><strong
              >北京中软汇金科技有限公司与中国科学院深圳先进技术研究院携手打造中国软件之金品。</strong
            ></span
          >
        </p>
        <p
          class="ordinary-output target-output"
          style="
            padding: 0px;
            margin-bottom: 0px;
            word-wrap: break-word;
            word-break: normal;
            color: rgb(106, 106, 106);
            font-family: Arial, 微软雅黑;
            font-size: 13px;
            white-space: normal;
            background-color: rgb(248, 248, 248);
          "
        >
          <span
            style="
              padding: 0px;
              margin: 0px;
              color: rgb(127, 127, 127);
              font-family: 微软雅黑;
            "
            microsoft=""
            font-size:=""
            ><br data-filtered="filtered" style="padding: 0px; margin: 0px"
          /></span>
        </p>
        <p
          class="ordinary-output target-output"
          style="
            padding: 0px;
            margin-bottom: 0px;
            word-wrap: break-word;
            word-break: normal;
            color: rgb(106, 106, 106);
            font-family: Arial, 微软雅黑;
            font-size: 13px;
            white-space: normal;
            background-color: rgb(248, 248, 248);
          "
        >
          <span
            style="
              color: rgb(127, 127, 127);
              font-size: 14px;
              font-family: 微软雅黑, MicrosoftYaHei;
            "
            >&nbsp; &nbsp; &nbsp;
            &nbsp;中软汇金面向行业客户，我们提供安全、可靠、高质量、易扩展的行业解决方案，帮助客户实现信息化管理最佳实践，以满足客户业务快速发展的不同需求。行业解决方案涵盖的领域包括：电信、能源、金融、政府（社会保障、财政、税务、公共安全、国土资源、海洋、质量监督检中软验检疫、工商、知识产权等）、制造业、商贸流通业、医疗卫生、教育、交通等行业。</span
          >
        </p>
        <div style="font-family: " microsoft="" white-space:="">
          <span
            style="
              color: rgb(127, 127, 127);
              font-size: 14px;
              font-family: 微软雅黑, MicrosoftYaHei;
            "
            >&nbsp; &nbsp; &nbsp;
            &nbsp;中软汇金将"超越技术"作为公司的经营思想和品牌承诺。作为一家以软件技术为核心的公司，我们通过开放式创新、卓越运营管理、人力资源发展等战略的实施，全面构造公司的核心竞争力，创造客户和社会的价值，从而实现技术的价值。</span
          >
        </div>
        <p style="font-family: ">
          <span
            style="
              color: rgb(127, 127, 127);
              font-size: 14px;
              font-family: 微软雅黑, MicrosoftYaHei;
            "
            >&nbsp; &nbsp; &nbsp;
            &nbsp;中软汇金致力于成为最受社会、客户、股东和员工尊敬的公司，并通过组织与过程的持续改进，领导力与员工竞争力的发展，联盟与开放式创新，使之成为国内优秀的IT解决方案和服务供应商。</span
          >
        </p>
        <p style="font-family: ">
          <span
            style="
              color: rgb(127, 127, 127);
              font-size: 14px;
              font-family: 微软雅黑, MicrosoftYaHei;
            "
            ><br
          /></span>
        </p>
        <table
          width="1161"
          class="table table-striped table-bordered table-hover"
        >
          <tbody style="padding: 0px; margin: 0px">
            <tr class="firstRow" style="padding: 0px; margin: 0px">
              <td
                width="30%"
                valign="top"
                style="padding: 0px; margin: 0px; word-break: break-all"
              >
                <p>
                  <img
                    src="http://www.chinasofthuijin.cn/upload/201809/1537767471537038.jpg"
                    alt="1537767471537038.jpg"
                    title="1537768738908812.png"
                    style="
                      border-width: initial;
                      border-style: none;
                      padding: 0px;
                      margin: 0px 0px 10px;
                      max-width: 100%;
                    "
                  />
                </p>
                <p>
                  <span
                    style="
                      font-family: 微软雅黑, MicrosoftYaHei;
                      background-color: transparent;
                    "
                    >在服务领域，提供包括应用开发和维护、系统实施与咨询服务、专业测试及性能工程服务、软件全球化与本地化服务、IT基础设施服务、业务流程外包（BPO）、IT培训等服务业务。</span
                  >
                </p>
                <p>
                  <span
                    style="
                      font-family: 微软雅黑, MicrosoftYaHei;
                      background-color: transparent;
                    "
                    ><br
                  /></span>
                </p>
                <p style="word-wrap: break-word; word-break: normal"><br /></p>
              </td>
              <td width="5" style="padding: 0px; margin: 0px"><br /></td>
              <td
                width="30%"
                valign="top"
                style="padding: 0px; margin: 0px; word-break: break-all"
              >
                <p>
                  <img
                    src="http://www.chinasofthuijin.cn/upload/201809/1537768770455353.jpg"
                    alt="1537768770455353.jpg"
                    title="1537768738908812.png"
                    style="
                      border-width: initial;
                      border-style: none;
                      padding: 0px;
                      margin: 0px 0px 10px;
                      max-width: 100%;
                    "
                  />
                </p>
                <p>
                  <span style="font-family: 微软雅黑, MicrosoftYaHei"
                    >面向行业客户，我们提供安全、可靠、高质量、易扩展的行业解决方案，帮助客户实现信息化管理最佳实践，以满足客户业务快速发展的不同需求。行业解决方案涵盖的领域包括：电信、能源、金融、政府（社会保障、财政、税务、公共安全、国土资源、海洋、质量监督检验检疫、工商、知识产权等）、制造业、商贸流通业、医疗卫生、教育、交通等行业。</span
                  >
                </p>
              </td>
              <td width="5" style="padding: 0px; margin: 0px"><br /></td>
              <td
                width="30%"
                valign="top"
                style="padding: 0px; margin: 0px; word-break: break-all"
              >
                <p>
                  <img
                    src="http://www.chinasofthuijin.cn/upload/201809/1537767427894689.jpg"
                    style="
                      padding: 0px;
                      margin: 0px 0px 10px;
                      border-width: initial;
                      border-style: none;
                      max-width: 100%;
                    "
                    alt="1537767427894689.jpg"
                    title="1537768770455353.jpg"
                  />
                </p>
                <p>
                  <span style="font-family: 微软雅黑, MicrosoftYaHei"
                    >将"超越技术"作为公司的经营思想和品牌承诺。作为一家<span
                      style="background-color: transparent"
                      >以软件技术为核心的公司，我们通过开放式创新、卓越运营管理、人力资源发展等战略的实施，全面构造公司的核心竞争力，创造客户和社会的价值，从而实现技术的价值。</span
                    ></span
                  >
                </p>
                <p><br /></p>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    </div>
    <div class="newsfooter"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      contentText: `&nbsp;&nbsp;本报济南4月17日电  （记者徐锦庚、李蕊）今年是胜利油田发现60周年。位于黄河入海口的胜利油田迄今累计生产原油12.5亿吨，占全国同期陆上原油产量1/5，目前保持年稳产2340万吨以上。`,
    };
  },
};
</script>
<style lang="scss" scoped>
.news-template {
  text-align: center;
  width: 100%;
  font-family: Hiragino Sans GB, Microsoft Yahei, \\5fae\8f6f\96c5\9ed1, SimSun,
    \\5b8b\4f53, Arial;
  .header {
    height: 6em;
    background: #04c9a0;
  }
  h3 {
    font-size: 1.7em;
    margin: 0.7em 0;
  }
  h5 {
    margin: 0.5em 0;
    color: #909399;
  }
  .content {
    width: 75%;
    margin: 0 12%;
    p {
      font-size: 1.2em;
      text-align: left;
      line-height: 2.2;
      margin-bottom: 2em;
      overflow-wrap: break-word;
      word-wrap: break-word;
    }
  }
}
</style>